@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  /* width: 2px;
  height: 2px; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.container {
  max-width: 90%;
  overflow-x: hidden;
}

.previewContainer {
  position: relative;
  transition: all 0.2s;
  margin-top: 20px;
}

.previewContainer img {
  opacity: 1;
  width: auto;
  height: auto;
  max-width: 350px;
  max-height: 200px;
  border: 4px solid black;
  margin-right: 10px;
  cursor: pointer;
}

.previewContainer svg {
  color: #ffffffba;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 1;
}

.previewContainer svg:hover {
  color: #c6011fca;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiAutocomplete-popper {
  z-index: 11111 !important;
}

.inputClass {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 16px 14px !important;
  border-radius: 10px;
  line-height: 24px !important;
}

#mmiPickerTop {
  border: 0px solid transparent !important;
  background-color: transparent !important;
}

#Dv_mmiSearch_map {
  background-color: #fff !important;
}

#mmiSearch_map {
  font-size: 14px;
  border: 0px solid transparent !important;
}

.mmi_search_dv {
  border-top: 0px solid transparent !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.document-review-message {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0;
  font-family: Arial, sans-serif;
  color: #343a40;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.document-review-message p {
  margin: 0;
  font-size: 16px;
}

 .note-container {
  /* background-color: #fff;
  border: 1px solid #ddd; */
  border-radius: 5px;
  padding: 15px;
  width: auto; /* Set width to auto */
  /* margin: 20px auto; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
} 

.note-title {
  /* font-size: 1.2em; */
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.note-text {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
  padding-left: 5px;
}

.highlight {
  font-weight: bold;
  color: #007bff;
}
